<template>
  <div>
    <!-- 导航栏 -->
    <NavBar />
    <!-- Banner -->
    <Media>
      <Banner />
      <template v-slot:mobile>
        <div>
          <img
            class="mobile-banner"
            src="@/assets/imon/banner-mobile.png"
            alt="">
          <div class="banner__tips">
          </div>
        </div>
      </template>
    </Media>
    <Item
      title="监测网站服务异常状态"
      title-color="color: #ff7e15;"
      info="天存睿眼对以下网站服务异常状态予以告警：">
      <div class="showcase__main module-a">
        <ModuleA />
      </div>
    </Item>
    <Item
      title="基于访问者视角的评估方法"
      title-color="color: #ff7e15;"
      info="天存睿眼既从普通访问者角度对网站在线服务品质和内容作出综合评价，也从专业安全人员视角对网站的风险态势进行分析研判。天存睿眼综合运用爬虫爬取、访问模拟、动态内容分析等多种技术手段，同时参考域名注册机构/政府监管部门/威胁情报系统等外部机构的情报信息从多个维度监测评估网站服务状态。">
      <media>
        <div class="showcase__main module-b">
          <img src="@/assets/imon/组 6@2x.png" alt="">
        </div>
        <template v-slot:mobile>
          <div class="showcase__main module-b">
            <img src="@/assets/imon/组 6@2xmob.png" alt="">
          </div>
        </template>
      </media>
    </Item>
    <Item
      class="page-last"
      title="私有化部署"
      title-color="color: #ff7e15;">
      <div class="showcase__main module-c">
        <img src="@/assets/imon/组 7@2x.png" alt="">
        <img src="@/assets/imon/组 8@2x.png" alt="">
      </div>
    </Item>
    <!-- 底部 -->
    <Footer />
  </div>
</template>>
<script>
import Banner from './components/banner'
import Item from '../components/item'
import ModuleA from './components/module-a'

export default {
  components: {
    Banner,
    Item,
    ModuleA
  }
}
</script>
<style lang="scss" scoped>
.banner__tips {
  @media screen and (max-width: 750px) {
    width: 100%;
    border: none;
    border-radius: px2rem(60);
    background: rgba(255, 255, 255, 1);
    padding: px2rem(100) px2rem(50) 0;
    margin-top: -40px;
    box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.1);
  }
}
.mobile-banner {
  width: 100%;
  font-size: 0;

  img {
    width: 100%;
  }
}
.showcase__main {
  &.module-b {
    text-align: center;
    margin: 0 auto;

    img {
      width: 775px;

      @media screen and (max-width: 750px) {
        width: 100%;
      }
    }
  }
  &.module-c {
    display: flex;
    justify-content: space-between;
    width: 74%;
    margin: auto;

    @media screen and (max-width: 750px) {
      flex-direction: column;
      padding: 70px 0 150px;
      width: 100%;

      img {
        width: 100% !important;
      }
    }

    img {
      width: 45%;
      height: 100%;
    }
  }
}
</style>
