<template>
  <div class="item page-container">
    <div
      v-if="title"
      class="item__title"
      :style="titleColor">{{ title }}</div>
    <div
      v-if="info"
      class="item__info text"
      v-html="info"></div>
    <slot></slot>
    <div
      v-if="tips"
      class="item__tips text"
      :style="tipsStyle"
      v-html="tips"></div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    titleColor: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: ''
    },
    tipsStyle: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.item__title {
  position: relative;
  font-family: 'Source Han Sans CN';
  font-size: px2rem(28);
  font-weight: 600;
  text-align: center;
  padding-bottom: 40px;

  @media screen and (max-width: 750px) {
    font-size: px2rem(60);
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.6;
    color: rgba(83, 83, 83, 1) !important;
    padding-bottom: px2rem(58);
    padding-left: px2rem(160);
    padding-right: px2rem(160);
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: px2rem(275);
      height: px2rem(20);
      border-radius: px2rem(10);
      background: rgba(42, 132, 230, 1);
    }
  }
}
.item__info {
  font-family: 'Source Han Sans CN';
  font-size: px2rem(16);
  font-weight: 400;
  color: #666666;
  letter-spacing: 1px;
  text-align: justify;
  padding-bottom: px2rem(50);
  line-height: 1.8;

  @media screen and (max-width: 750px) {
    font-size: px2rem(42);
    letter-spacing: px2rem(1);
    line-height: 1.8;
    text-align: justify;
    padding-top: px2rem(50);
  }
}
.item__tips {
  font-size: px2rem(18);
  font-weight: 500;
  color: #666666;
  padding-bottom: px2rem(50);
  text-align: center;
  line-height: 2;

  @media screen and (max-width: 750px) {
    font-size: px2rem(42) !important;
    letter-spacing: px2rem(1) !important;
    line-height: 1.8 !important;
    padding-top: px2rem(10) !important;
    padding-left: px2rem(50);
    padding-right: px2rem(50);
    padding-bottom: px2rem(80);
  }
}
</style>
